<template>
	<source
		:media="media"
		:srcset="cSizes.srcset"
		:height="height"
		:width="width"
		:sizes="cSizes.sizes"
	/>
</template>

<script>
export default {
	props: {
		src: { type: String },
		media: { type: String },
		sizes: { type: String },
		height: { type: String },
		width: { type: String },
		modifiers: { type: Object, default: () => {} },
	},
	computed: {
		cSizes() {
			return useImage().getSizes(this.src, {
				sizes: this.sizes,
				modifiers: this.modifiers,
			});
		},
	},
};
</script>
